'use client'

import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { useSignals } from '@preact/signals-react/runtime'
import ClientLink from '@susu/headless-commerce/components/Link/ClientLink'
import { useCountry } from '@susu/headless-commerce/contexts/country/client'
import { customer } from '@susu/headless-commerce/contexts/customer'
import { useLocale } from '@susu/headless-commerce/contexts/locale/client'
import type {
  NavigationLink,
  TextWrapperRich,
} from '@susu/headless-commerce/gql/generated/graphql'
import { usePageType } from '@susu/headless-commerce/hooks/usePageType'
import { generateUrlFromLinkContent } from '@susu/headless-commerce/utils/UrlGenerator'
import type { PromotionEvents } from '@susu/headless-commerce/utils/tracking/tracking'
import {
  trackClickPromotion,
  trackImpressionPromotion,
} from '@susu/headless-commerce/utils/tracking/tracking'
import { useCallback, useEffect, useRef } from 'react'

import styles from './FooterUsp.module.scss'

export default function ClientFooterLink({ item }: { item: TextWrapperRich }) {
  useSignals()

  const country = useCountry()
  const locale = useLocale()
  const pageType = usePageType()
  const uspLinkRef = useRef<HTMLDivElement>(null)

  const handleClick = useCallback(() => {
    if (customer.value) {
      trackClickPromotion(
        uspLinkRef,
        item?.cta?.link?.promotionEvents as PromotionEvents,
        pageType,
        {
          locale,
          country,
        },
      )
    }
  }, [country, item?.cta?.link?.promotionEvents, locale, pageType])

  useEffect(() => {
    if (uspLinkRef?.current) {
      trackImpressionPromotion(
        uspLinkRef,
        item?.cta?.link?.promotionEvents as PromotionEvents,
        pageType,
        {
          locale,
          country,
        },
      )
    }
  }, [country, item?.cta?.link?.promotionEvents, locale, pageType])

  return (
    <div ref={uspLinkRef}>
      <ClientLink
        className={styles['footer-usp__link']}
        onClick={handleClick}
        href={generateUrlFromLinkContent(
          item.cta?.link as NavigationLink,
          country.siteID,
          locale,
        )}
        title={item.cta?.title ?? ''}
        size="md"
        theme="dark"
        underline
        hoverEffect
        automationId={String(item.cta?.testAutomationAttribute)}
      >
        {documentToReactComponents(item.cta?.text?.json)}
      </ClientLink>
    </div>
  )
}
