'use client'

import { useSignals } from '@preact/signals-react/runtime'
import type { Locale } from '@susu/headless-commerce/config/locale'
import { customer } from '@susu/headless-commerce/contexts/customer'
import { usePageType } from '@susu/headless-commerce/hooks/usePageType'
import type { CountryConfiguration } from '@susu/headless-commerce/types/CountryConfiguration'
import type { PromotionEvents } from '@susu/headless-commerce/utils/tracking/tracking'
import {
  trackClickPromotion,
  trackImpressionPromotion,
} from '@susu/headless-commerce/utils/tracking/tracking'
import type { ReactNode } from 'react'
import { useCallback, useEffect, useRef } from 'react'

import styles from './FooterStore.module.scss'

export type ClientFooterStoreBlockProps = {
  locale: Locale
  country: CountryConfiguration
  children?: ReactNode
  promotionEvents: PromotionEvents
}

export default function ClientFooterStore({
  children,
  promotionEvents,
  locale,
  country,
}: ClientFooterStoreBlockProps) {
  useSignals()
  const pageType = usePageType()
  const footerStoreRef = useRef<HTMLDivElement>(null)

  const handleInteractiveEvent = useCallback(() => {
    if (customer.value) {
      trackClickPromotion(footerStoreRef, promotionEvents, pageType, {
        locale,
        country,
      })
    }
  }, [country, locale, pageType, promotionEvents])

  useEffect(() => {
    if (footerStoreRef?.current) {
      trackImpressionPromotion(footerStoreRef, promotionEvents, pageType, {
        locale,
        country,
      })
    }
  }, [country, locale, pageType, promotionEvents])

  return (
    <div
      className={styles['footer-store-container']}
      onClick={handleInteractiveEvent}
      onKeyDown={handleInteractiveEvent}
      ref={footerStoreRef}
      // biome-ignore lint/a11y/useSemanticElements: <explanation>
      role="button"
      tabIndex={0}
    >
      {children}
    </div>
  )
}
