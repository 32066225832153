'use client'

import ExternalLink from '@susu/headless-commerce/components/ExternalLink/ExternalLink'
import Icon from '@susu/headless-commerce/components/Icon/Icon'
import { useCountry } from '@susu/headless-commerce/contexts/country/client'
import { useLocale } from '@susu/headless-commerce/contexts/locale/client'
import type {
  LinkWithIcon,
  NavigationLink,
  SocialLinksWrapper,
} from '@susu/headless-commerce/gql/generated/graphql'
import { usePageType } from '@susu/headless-commerce/hooks/usePageType'
import { generateUrlFromLinkContent } from '@susu/headless-commerce/utils/UrlGenerator'
import { URLData } from '@susu/headless-commerce/utils/tracking/segment'
import { useCallback } from 'react'

import { trackFooterClick } from '@susu/headless-commerce/features/configurator/utils/trackFooterClick'
import styles from './FooterBottom.module.scss'

export type ClientSocialMediaProps = {
  contentSectionsCollection: SocialLinksWrapper['contentSectionsCollection']
}

export default function ClientSocialMedia({
  contentSectionsCollection,
}: ClientSocialMediaProps) {
  const locale = useLocale()
  const country = useCountry()
  const { url, locationId } = URLData()
  const pageType = usePageType()

  const createHandleClick = useCallback(
    (item: LinkWithIcon) => () => {
      if (!item?.link?.promotionEvents) {
        return
      }

      trackFooterClick({
        promotionEvents: item?.link?.promotionEvents,
        country,
        locale,
        locationId,
        pageType,
        url,
      })
    },
    [country, locale, locationId, pageType, url],
  )

  return (
    <>
      {contentSectionsCollection?.items.map((item) => {
        return (
          <ExternalLink
            key={item?.iconName}
            onClick={createHandleClick(item as LinkWithIcon)}
            href={generateUrlFromLinkContent(
              item?.link as NavigationLink,
              country.siteID,
              locale,
            )}
            automationId={String(item?.link?.automationId)}
          >
            <span className={styles['footer-bottom-social-icon']}>
              <Icon icon={String(item?.iconName)} aria-hidden="true" />
            </span>
          </ExternalLink>
        )
      })}
    </>
  )
}
