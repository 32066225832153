import type { Locale } from '@susu/headless-commerce/config/locale'
import type { CountryConfiguration } from '@susu/headless-commerce/types/CountryConfiguration'
import type { PageType } from '@susu/headless-commerce/types/PageType'
import type { PromotionEvents } from '@susu/headless-commerce/utils/tracking/tracking'
import { trackEvent } from '@susu/headless-commerce/utils/tracking/tracking'

export type TrackFooterLinkProps = {
  country: CountryConfiguration
  lastPathUrl?: string
  locale: Locale
  locationId: string
  pageType: PageType
  promotionEvents: PromotionEvents
  url: string
}

export const trackFooterClick = async ({
  promotionEvents,
  country,
  locale,
  lastPathUrl,
  locationId,
  pageType,
  url,
}: TrackFooterLinkProps) => {
  const promotionEventsData = promotionEvents || {}
  await trackEvent({
    ga: {
      eventCategory: String(promotionEventsData.promotionEventCategory),
      eventAction: String(promotionEventsData.promotionEventAction),
      eventLabel: String(promotionEventsData.promotionEventLabel),
    },
    segment: {
      event: 'footerClick',
      enrichProps: {
        country,
        locale,
      },
      properties: {
        eventCategory: 'footer_interactions',
        eventLabel:
          lastPathUrl ??
          promotionEventsData?.promotionEventLabel?.toLocaleLowerCase() ??
          '',
        eventLocation: 'footer',
        locationId,
        pageType,
        url,
      },
    },
  })
}
