'use client'

import ExternalLink from '@susu/headless-commerce/components/ExternalLink/ExternalLink'
import { useCountry } from '@susu/headless-commerce/contexts/country/client'
import { useLocale } from '@susu/headless-commerce/contexts/locale/client'
import { trackFooterClick } from '@susu/headless-commerce/features/configurator/utils/trackFooterClick'
import type {
  NavigationLink,
  SiteId,
} from '@susu/headless-commerce/gql/generated/graphql'
import { usePageType } from '@susu/headless-commerce/hooks/usePageType'
import { generateUrlFromLinkContent } from '@susu/headless-commerce/utils/UrlGenerator'
import {
  URLData,
  getLastPathUrl,
} from '@susu/headless-commerce/utils/tracking/segment'
import type { PromotionEvents } from '@susu/headless-commerce/utils/tracking/tracking'
import { useCallback, useRef } from 'react'
import styles from './FooterContactAndLinks.module.scss'

export type ClientSectionProps = {
  title: string
  link: NavigationLink
}

export default function ClientSection({ title, link }: ClientSectionProps) {
  const promotionEvents = link?.promotionEvents as PromotionEvents
  const linkRef = useRef<HTMLAnchorElement>(null)
  const country = useCountry()
  const locale = useLocale()
  const pageType = usePageType()
  const { locationId } = URLData()
  const linkUrl = generateUrlFromLinkContent(
    link as NavigationLink,
    country.siteID as SiteId,
    locale,
  )
  const lastPathUrl = getLastPathUrl(linkUrl)

  const handleClick = useCallback(() => {
    trackFooterClick({
      promotionEvents,
      country,
      locale,
      lastPathUrl,
      locationId,
      pageType,
      url: linkUrl,
    })
  }, [
    promotionEvents,
    country,
    locale,
    lastPathUrl,
    locationId,
    pageType,
    linkUrl,
  ])

  return (
    <ExternalLink
      automationId={String(link?.automationId)}
      className={`${styles['footer-links__link']} body-small-light`}
      href={linkUrl}
      onClick={handleClick}
      ref={linkRef}
    >
      {title}
    </ExternalLink>
  )
}
