'use client'

import ExternalLink from '@susu/headless-commerce/components/ExternalLink/ExternalLink'
import { useCountry } from '@susu/headless-commerce/contexts/country/client'
import { useLocale } from '@susu/headless-commerce/contexts/locale/client'
import type {
  Cta,
  NavigationLink,
  SiteId,
} from '@susu/headless-commerce/gql/generated/graphql'
import { usePageType } from '@susu/headless-commerce/hooks/usePageType'
import { generateUrlFromLinkContent } from '@susu/headless-commerce/utils/UrlGenerator'
import { URLData } from '@susu/headless-commerce/utils/tracking/segment'
import { useCallback } from 'react'

import { trackFooterClick } from '@susu/headless-commerce/features/configurator/utils/trackFooterClick'
import styles from './FooterBottom.module.scss'

export type ClientTermsProps = {
  cta: Cta
  siteID: SiteId
}

export default function ClientTerms({ cta, siteID }: ClientTermsProps) {
  const { locationId } = URLData()
  const country = useCountry()
  const locale = useLocale()
  const pageType = usePageType()
  const url = generateUrlFromLinkContent(
    cta.link as NavigationLink,
    siteID,
    locale,
  )

  const handleClick = useCallback(() => {
    trackFooterClick({
      promotionEvents: cta?.link?.promotionEvents ?? {},
      country,
      locale,
      locationId,
      pageType,
      url,
    })
  }, [cta, country, locale, locationId, pageType, url])

  return (
    <ExternalLink
      automationId={String(cta.link?.automationId)}
      className={styles['footer-terms-condition']}
      href={url}
      onClick={handleClick}
    >
      {cta.title}
    </ExternalLink>
  )
}
