'use client'

import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { useSignals } from '@preact/signals-react/runtime'
import Link from '@susu/headless-commerce/components/Link/ClientLink'
import ClientSideSlider from '@susu/headless-commerce/components/SideSlider/ClientSideSlider'
import { useCountry } from '@susu/headless-commerce/contexts/country/client'
import { customer } from '@susu/headless-commerce/contexts/customer'
import { useLocale } from '@susu/headless-commerce/contexts/locale/client'
import type { TextWrapperRichPanel } from '@susu/headless-commerce/gql/generated/graphql'
import { usePageType } from '@susu/headless-commerce/hooks/usePageType'
import type { PromotionEvents } from '@susu/headless-commerce/utils/tracking/tracking'
import {
  trackClickPromotion,
  trackImpressionPromotion,
} from '@susu/headless-commerce/utils/tracking/tracking'
import type { ReactNode } from 'react'
import { useCallback, useEffect, useRef, useState } from 'react'

import styles from './FooterUsp.module.scss'

export type ClientPanelCTAProps = {
  children?: ReactNode
  item: TextWrapperRichPanel
}

export default function ClientPanelCTA({
  item,
  children,
}: Readonly<ClientPanelCTAProps>) {
  useSignals()
  const country = useCountry()
  const locale = useLocale()
  const pageType = usePageType()
  const [panel] = item.styleExpertPanelCollection?.items ?? []
  const [isPanelSliderOpen, setIsPanelSliderOpen] = useState<boolean>(false)
  const CTARef = useRef<HTMLSpanElement>(null)

  const handleClick = useCallback(() => {
    setIsPanelSliderOpen(true)
    if (customer.value) {
      trackClickPromotion(
        CTARef,
        item?.cta?.link?.promotionEvents as PromotionEvents,
        pageType,
        {
          locale,
          country,
        },
      )
    }

    setTimeout(() => {
      document
        .querySelector('[class*="header"]')
        ?.classList.add('header-is-hidden')
    }, 200)
  }, [country, item?.cta?.link?.promotionEvents, locale, pageType])

  const handlePanelClose = useCallback(() => {
    setIsPanelSliderOpen(false)
    document
      .querySelector('[class*="header"]')
      ?.classList.remove('header-is-hidden')
  }, [])

  useEffect(() => {
    trackImpressionPromotion(
      CTARef,
      item?.cta?.link?.promotionEvents as PromotionEvents,
      pageType,
      {
        locale,
        country,
      },
    )
  }, [country, item?.cta?.link?.promotionEvents, locale, pageType])

  return (
    <>
      <Link
        className={styles['footer-usp__link']}
        onClick={handleClick}
        title={item?.title ?? ''}
        href=""
        theme="dark"
        size="md"
        underline
        hoverEffect
        automationId={String(item?.cta?.link?.automationId)}
      >
        <span ref={CTARef}>
          {documentToReactComponents(item.cta?.text?.json)}
        </span>
      </Link>
      {panel?.sys?.id && (
        <ClientSideSlider
          isOpen={isPanelSliderOpen}
          onClose={handlePanelClose}
          slideInFrom={'right'}
          fullScreen="mobile"
          hasCloseButton={true}
        >
          {children}
        </ClientSideSlider>
      )}
    </>
  )
}
