'use client'

import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import ExternalLink from '@susu/headless-commerce/components/ExternalLink/ExternalLink'
import type { Locale } from '@susu/headless-commerce/config/locale'
import { useCountry } from '@susu/headless-commerce/contexts/country/client'
import { useLocale } from '@susu/headless-commerce/contexts/locale/client'
import type {
  ContactInfoWrapper,
  NavigationLink,
  PhoneInfo,
  PhoneInfoValue,
  SiteId,
} from '@susu/headless-commerce/gql/generated/graphql'
import { usePageType } from '@susu/headless-commerce/hooks/usePageType'
import { generateUrlFromLinkContent } from '@susu/headless-commerce/utils/UrlGenerator'
import {
  createRenderNodeForPhoneNumber,
  createRenderOptionsForICP,
} from '@susu/headless-commerce/utils/contentfulUtils'
import { URLData } from '@susu/headless-commerce/utils/tracking/segment'
import { useCallback } from 'react'

import { trackFooterClick } from '@susu/headless-commerce/features/configurator/utils/trackFooterClick'
import styles from './FooterContactAndLinks.module.scss'

const linkClass = 'footer-links__link'

// biome-ignore lint/suspicious/noExplicitAny: <explanation>
const getPhoneNumberInfo = (inline: any[], countryCode: string): string => {
  let phoneNumberInfo: PhoneInfoValue | null = null
  if (inline?.length) {
    // Loop through the inline linked entries and add them to the map.
    for (const entry of inline ?? []) {
      const phonesCollection = (entry as PhoneInfo)?.phoneNumbersCollection
        ?.items

      if (phonesCollection?.length === 1) {
        phoneNumberInfo = phonesCollection[0] as PhoneInfoValue
      }

      if (phonesCollection?.length === 2) {
        phoneNumberInfo = phonesCollection.find(
          (phoneData) => phoneData?.countryCode === countryCode,
        ) as PhoneInfoValue
      }
    }
  }

  return phoneNumberInfo?.phoneValue || ''
}

const getPhoneNumberInfoRenderNode = (
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  inline: any[],
  countryCode: string,
): PhoneInfoValue | null => {
  let phoneNumberInfo: PhoneInfoValue | null = null

  if (inline?.length) {
    // Loop through the inline linked entries and add them to the map.
    for (const entry of inline ?? []) {
      const phonesCollection = (entry as PhoneInfo)?.phoneNumbersCollection
        ?.items

      if (phonesCollection?.length === 1) {
        phoneNumberInfo = phonesCollection[0] as PhoneInfoValue
      }

      if (phonesCollection?.length === 2) {
        phoneNumberInfo = phonesCollection.find(
          (phoneData) => phoneData?.countryCode === countryCode,
        ) as PhoneInfoValue
      }
    }
  }

  return phoneNumberInfo
}

const getHref = (
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  item: any,
  countryCode: string,
  siteID: SiteId,
  locale: Locale,
) => {
  if (
    item?.text?.links?.entries?.inline[0]?.phoneNumbersCollection?.items?.length
  ) {
    return `tel:${getPhoneNumberInfo(item?.text?.links?.entries?.inline, countryCode)}`
  }

  if (item?.text?.links?.entries?.inline[0]?.__typename === 'Cta') {
    return 'https://www.beian.miit.gov.cn/'
  }

  return generateUrlFromLinkContent(item.link as NavigationLink, siteID, locale)
}

export type ClientContactSectionProps = {
  icpTitle: string
  item: ContactInfoWrapper
}

export default function ClientContactSection({
  item,
  icpTitle,
}: ClientContactSectionProps) {
  const country = useCountry()
  const locale = useLocale()
  const { url, locationId } = URLData()
  const pageType = usePageType()

  const handleClick = useCallback(() => {
    if (!item?.link?.promotionEvents) {
      return
    }

    trackFooterClick({
      promotionEvents: item?.link?.promotionEvents,
      country,
      locale,
      locationId,
      pageType,
      url,
    })
  }, [item?.link?.promotionEvents, country, locale, locationId, pageType, url])

  return (
    <>
      {item?.title && (
        <div className={styles['footer-links__label']}>{item?.title}</div>
      )}
      {item?.text?.json && (
        <ExternalLink
          className={
            // @ts-expect-error
            item?.text?.links?.entries?.inline[0]?.__typename === 'Cta'
              ? styles['footer-links__label']
              : styles[linkClass]
          }
          href={getHref(
            item,
            country.countryCode,
            country.siteID as SiteId,
            locale,
          )}
          onClick={handleClick}
          automationId={String(item?.link?.automationId)}
          opensInNewTab={true}
        >
          {documentToReactComponents(
            item?.text?.json,
            // @ts-expect-error
            item?.text?.links?.entries?.inline[0]?.__typename === 'Cta'
              ? createRenderOptionsForICP(icpTitle)
              : createRenderNodeForPhoneNumber(
                  getPhoneNumberInfoRenderNode(
                    item?.text?.links?.entries?.inline,
                    country.countryCode,
                  ),
                ),
          )}
        </ExternalLink>
      )}
    </>
  )
}
