'use client'

import { useSignals } from '@preact/signals-react/runtime'
import { useCountry } from '@susu/headless-commerce/contexts/country/client'
import { customer } from '@susu/headless-commerce/contexts/customer'
import { useLocale } from '@susu/headless-commerce/contexts/locale/client'
import type {
  FooterNewsletterBlockQuery,
  FooterNewsletterBlockQueryVariables,
} from '@susu/headless-commerce/gql/generated/footerNewsletter.operation'
import { usePageType } from '@susu/headless-commerce/hooks/usePageType'
import type { PromotionEvents } from '@susu/headless-commerce/utils/tracking/tracking'
import { trackImpressionPromotion } from '@susu/headless-commerce/utils/tracking/tracking'
import type { ReactNode } from 'react'
import { useEffect, useRef } from 'react'
import type { OperationResult } from 'urql/core'

import styles from './FooterNewsletter.module.scss'

export type ClientFooterNewsletterContainerProps = {
  children?: ReactNode
  query: OperationResult<
    FooterNewsletterBlockQuery,
    FooterNewsletterBlockQueryVariables
  >
}

export default function ClientFooterNewsletterContainer({
  children,
  query,
}: Readonly<ClientFooterNewsletterContainerProps>) {
  useSignals()
  const locale = useLocale()
  const country = useCountry()
  const pageType = usePageType()
  const footerNewsletterRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (footerNewsletterRef?.current && customer.value) {
      trackImpressionPromotion(
        footerNewsletterRef,
        query?.data?.footerNewsletterBlock?.promotionEvents as PromotionEvents,
        pageType,
        {
          locale,
          country,
        },
      )
    }
  }, [
    country,
    locale,
    pageType,
    query?.data?.footerNewsletterBlock?.promotionEvents,
  ])

  return (
    <div className={styles['footer-newsletter']} ref={footerNewsletterRef}>
      {children}
    </div>
  )
}
