'use client'

import { useSignals } from '@preact/signals-react/runtime'
import ExternalLink from '@susu/headless-commerce/components/ExternalLink/ExternalLink'
import { useCountry } from '@susu/headless-commerce/contexts/country/client'
import { customer } from '@susu/headless-commerce/contexts/customer'
import { useLocale } from '@susu/headless-commerce/contexts/locale/client'
import type {
  LinkWithImage,
  NavigationLink,
} from '@susu/headless-commerce/gql/generated/graphql'
import { usePageType } from '@susu/headless-commerce/hooks/usePageType'
import { generateUrlFromLinkContent } from '@susu/headless-commerce/utils/UrlGenerator'
import type { PromotionEvents } from '@susu/headless-commerce/utils/tracking/tracking'
import {
  trackClickPromotion,
  trackImpressionPromotion,
} from '@susu/headless-commerce/utils/tracking/tracking'
import type { ReactNode } from 'react'
import { useCallback, useEffect, useRef } from 'react'

export type ClientCSRProps = {
  children?: ReactNode
  linkWithImage: LinkWithImage
}

export default function ClientCSR({ children, linkWithImage }: ClientCSRProps) {
  useSignals()
  const pageType = usePageType()
  const fairwearLinkRef = useRef<HTMLAnchorElement>(null)
  const locale = useLocale()
  const country = useCountry()

  const handleCSRClick = useCallback(() => {
    if (linkWithImage?.link?.promotionEvents && customer.value) {
      trackClickPromotion(
        fairwearLinkRef,
        linkWithImage?.link?.promotionEvents as PromotionEvents,
        pageType,
        {
          locale,
          country,
        },
      )
    }
  }, [country, linkWithImage?.link?.promotionEvents, locale, pageType])

  useEffect(() => {
    if (linkWithImage && fairwearLinkRef?.current && customer.value) {
      trackImpressionPromotion(
        fairwearLinkRef,
        linkWithImage.link?.promotionEvents as PromotionEvents,
        pageType,
        {
          locale,
          country,
        },
      )
    }
  }, [country, linkWithImage, locale, pageType])

  return (
    <span ref={fairwearLinkRef}>
      <ExternalLink
        onClick={handleCSRClick}
        href={generateUrlFromLinkContent(
          linkWithImage?.link as NavigationLink,
          country.siteID,
          locale,
        )}
        automationId={String(linkWithImage?.link?.automationId)}
      >
        {children}
      </ExternalLink>
    </span>
  )
}
